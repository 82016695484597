import { createInertiaApp, Link, router } from '@inertiajs/vue3';
import * as Sentry from '@sentry/vue';
import { VueQueryPlugin } from '@tanstack/vue-query';
import axios from 'axios';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import type { DefineComponent } from 'vue';
import { createApp, h } from 'vue';
import VueDiff from 'vue-diff';
import 'vue-diff/dist/index.css';
import VueTippy from 'vue-tippy';
import type { PluginOptions } from 'vue-toastification';
import Toast, { POSITION, useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { ZiggyVue } from 'ziggy-js';

import { createPinia } from 'pinia';
import '../css/app.css';
import Btn from './Components/Btn.vue';
import Icon from './Components/Icon.vue';
import AppLayout from './Layouts/App.vue';
import { hasPermission, hasRole } from './lib/usePermissions';
import { translate } from './util';

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

createInertiaApp({
    resolve: async (name) => {
        const page = await resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob<DefineComponent>('./Pages/**/*.vue'));
        page.default.layout = page.default.layout || AppLayout;
        return page;
    },

    setup({ el, App, props, plugin: InertiaPlugin }) {
        const pinia = createPinia();
        const app = createApp({ render: () => h(App, props) })
            .use(InertiaPlugin)
            .use(pinia)
            .use(ZiggyVue, Ziggy)
            .use(VueQueryPlugin)
            .use(VueTippy, {
                defaultProps: { theme: 'light-border' },
            })
            .use(VueDiff)
            .use(Toast, {
                icon: false,
                position: POSITION.BOTTOM_RIGHT,
            } satisfies PluginOptions)
            .mixin({ methods: { __: translate, $can: hasPermission, $is: hasRole } })
            .component('InertiaLink', Link)
            .component('Btn', Btn)
            .component('Icon', Icon);

        if (import.meta.env.PROD) {
            Sentry.init({
                app,
                dsn: 'https://6793c6f1110946cbba8efbcb746baf6b@o126420.ingest.sentry.io/6479108',
            });
        }

        app.mount(el);
    },
});

router.on('success', ({ detail: { page } }) => {
    const toast = useToast();
    if (page.props.message) {
        toast.warning(page.props.message);
    }
});
