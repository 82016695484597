<template>
    <div class="min-h-screen bg-gray-100">
        <div id="dropdown-teleport-target" />
        <nav class="mb-6 bg-white py-4 shadow-sm print:hidden">
            <div class="mx-1 flex items-center justify-between 2xl:container">
                <div class="flex items-center">
                    <InertiaLink :href="route('invites.index')" class="mr-4 text-lg">
                        {{ settings.general.site_name }}
                    </InertiaLink>
                    <MainMenu v-if="user" class="hidden flex-wrap items-center gap-y-2 overflow-hidden md:flex" />
                </div>

                <Dropdown v-if="user" :auto-close="true" placement="bottom-end" class="md:hidden">
                    <template #default>
                        <div type="button" class="px-2 text-sm text-gray-600">
                            {{ user.name }}
                            <ChevronDownIcon class="inline-block h-4 w-4" />
                        </div>
                    </template>
                    <template #dropdown>
                        <div class="mt-2 flex flex-col items-end gap-4 rounded-sm bg-white px-4 py-6 shadow-xl">
                            <MainMenu class="flex flex-col items-end gap-4" />
                            <button class="px-2 text-sm text-gray-600" @click="$inertia.post(route('logout'))">
                                {{ __('Logout') }}
                            </button>
                        </div>
                    </template>
                </Dropdown>
                <button
                    v-if="user"
                    type="button"
                    class="hidden flex-none px-2 text-sm text-gray-600 md:block"
                    @click="$inertia.post(route('logout'))"
                >
                    {{ user.name }}
                    |
                    {{ __('Logout') }}
                </button>
            </div>
        </nav>

        <div class="mx-1 2xl:container">
            <slot></slot>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ChevronDownIcon } from '@heroicons/vue/24/solid';
import { Link as InertiaLink, usePage } from '@inertiajs/vue3';
import { computed } from 'vue';

import Dropdown from '../Components/Dropdown.vue';
import MainMenu from '../Components/MainMenu.vue';

(window as any).layoutLoaded = true;

const page = usePage<AuthenticatedPageProps>();
const user = computed(() => page.props.auth.user);
const settings = computed(() => page.props.settings);
</script>
